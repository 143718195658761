<template>
  <div class="d-flex flex-column align-center pb-3">
    <div class="select-location-component">
      <h2 class="page-title-1 mt-10 mb-5">
        {{ t('Select site') }}
      </h2>
      <eewc-select-box
        :placeholder="t('Select site')"
        selection-slot
        item-slot
        :value="currentLocation"
        :items="locations.locations.map(location => ({text: location.name, value: location.id, ...location}))"
        @input="selectLocation"
      >
        <template #selection="{item}">
          {{ item.name }}
        </template>
        <template #item="{item}">
          {{ item.name }}
        </template>
      </eewc-select-box>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { t } from '@/plugins/i18n';
import { useLocationsStore, useNewFloorStore } from '@/stores'
import { BasicLocation } from '@eencloud/eewc-components/src/service/api-types'

const props = defineProps<{
  stepIndex: number
}>()

const locations = useLocationsStore()
const newFloorStore = useNewFloorStore()

// Select button
const currentLocation = computed(() => {
  return {
    text: newFloorStore?.selectedLocation?.name,
    value: newFloorStore?.selectedLocation?.id,
    ...newFloorStore?.selectedLocation
  }
})

const selectLocation = async function(location: BasicLocation) {
  newFloorStore.$patch({ selectedLocation: location })
  newFloorStore.setIsCompletedStep(props.stepIndex, true)
  await newFloorStore.getAllFloorsByLocationId()
}
</script>

<style scoped lang="scss">
@import "../../../../styles/public/main.scss";

.select-location-component {
  max-width: 430px;
  width: 100%;
}

.page-title-1 {
  @include subtitle-1;
}
</style>
