<template>
  <div class="d-flex align-center">
    <eewc-tooltip
      :text="'Add devices'"
    >
      <template #content="{on, attrs}">
        <div
          class="mr-3 ml-auto"
          v-bind="attrs"
          v-on="on"
        >
          <eewc-button-common
            type="outlined"
            :icon="true"
            append-icon="$icon_plus"
            color="primary"
            @click="openAddDevicesSidebar()"
          />
        </div>
      </template>
    </eewc-tooltip>
    <eewc-tooltip
      :text="floors.currentEditingCamera ? 'Remove device' : 'A device must be selected to delete it'"
    >
      <template #content="{on, attrs}">
        <div
          class="mr-13"
          v-bind="attrs"
          v-on="on"
        >
          <eewc-button-common
            type="outlined"
            :icon="true"
            append-icon="$icon_delete"
            color="primary"
            :disabled="!floors.currentEditingCamera"
            @click="deleteCameraFromFloor(floors.currentEditingCamera)"
          />
        </div>
      </template>
    </eewc-tooltip>
    <eewc-button-common
      class="mr-3"
      color="accent"
      @click="onClickDone()"
    >
      {{ t('Done') }}
    </eewc-button-common>
  </div>
</template>
<script setup lang="ts">
// EDIT
import { ApiCameraWithIncludes } from '@eencloud/eewc-components/src/service/api-types'
import api from '@eencloud/eewc-components/src/service/api'
import { t } from '@/plugins/i18n';

import { useCamerasStore, useFloorsStore, useLocationsStore, useMessagingStore, useAppStore } from '@/stores'

const floors = useFloorsStore()
const cameras = useCamerasStore()
const locations = useLocationsStore()
const messagingStore = useMessagingStore();
const appStore = useAppStore();

const onClickDone = () => {
  floors.$patch({
    isAddingCamerasToFloorplanActive: false,
    currentEditingCamera: undefined
  })
  floors.$patch({ isEditCamerasFloorEnabled: false })

}

function deleteCameraFromFloor(cameraToDelete: ApiCameraWithIncludes) {
  const deleteCameraWarningText = {
    title: 'Remove device from floor plan?',
    body: `You are going to remove ${cameraToDelete.name} from the floor plan.`,
    buttonMessage: 'Remove device'
  }
  messagingStore.deleteDialog({
    type: 'camera',
    item: cameraToDelete,
    deleteCallback: async () => {
      try {
        await api.editCameraDevicePosition({
          floor: null
        }, cameraToDelete.id)

        if (cameraToDelete.devicePosition && Number.isInteger(cameraToDelete.devicePosition.floor)) {
          cameraToDelete.devicePosition.floor = null

          const cameraPayloadIndex = cameras.cameras.findIndex((camera) => camera.id === cameraToDelete.id)
          cameras.$patch((state) => {
            state.cameras.splice(cameraPayloadIndex, 1, cameraToDelete)
          })

          floors.currentEditingCamera = null

          locations.setCurrentLocationCameras()
          floors.setCurrentFloorCameras()
          appStore.$patch({ addCamSidebarKey: appStore.addCamSidebarKey+1 });
        }
      } catch (error) {
        console.error(error)
      }
    },
  }, deleteCameraWarningText);
}

function openAddDevicesSidebar() {
  floors.$patch({
    isAddingCamerasToFloorplanActive: true,
    currentEditingCamera: undefined
  })
}
</script>
<style lang="scss">
@import "../../styles/public/main.scss";

</style>
