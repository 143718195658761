import VueRouter from 'vue-router'
import FloorPlan from '@/pages/FloorPlan/FloorPlanView.vue'
import NoFloorplan from '@/pages/FloorPlan/NoFloorplan.vue'
import NewFloorplan from '@/pages/FloorPlan/NewFloorplan/NewFloorplan'
import LiveVideoPlayer from '@/pages/LiveVideoPlayer/LiveVideoPlayer.vue';
import ApiList from "@eencloud/eewc-components/src/service/api";

import {
  useAppStore,
  useAuthStateStore,
  useFeedsStore,
  useLocationsStore,
  useUsersStore,
} from '@/stores'

import { requestBaseUrl } from '../main';

const routes = [
  {
    path: '/location/:locationId/floor_plan/:floorPlanId',
    name: 'Floor plan',
    component: FloorPlan,
  },
  {
    path: '/no-floorplan',
    name: 'No floorplan',
    component: NoFloorplan,
  },
  {
    path: '/new-floor-plan',
    name: 'New floor plan',
    component: NewFloorplan,
  },
  {
    path: '/location/:locationId/floor_plan/:floorPlanId/video/:cameraId',
    name: 'Live Video',
    component: LiveVideoPlayer,
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  const authStateStore = useAuthStateStore();
  const locationsStore = useLocationsStore();
  const usersStore = useUsersStore();
  const feedsStore = useFeedsStore();
  const appStore = useAppStore();

  if (location.href.split('?')[1] && !authStateStore.loggedIn) {
    const params = location.href.split('?')[1].split('&')
    const data = {}
    let param
    for (param in params) {
      data[params[param].split('=')[0]] = params[param].split('=')[1]
    }

    ApiList.token = data['access_token']

    if (data['base_url']) {
      ApiList.baseURL = data['base_url']
    } else {
      await requestBaseUrl()
    }

    if (data['is_new_webapp']) {
      appStore.isNewWebapp = true;
    }
    authStateStore.loginSuccessful()

    Promise.allSettled([
      locationsStore.getAllLocations(),
      feedsStore.fetchAllFeeds(),
      usersStore.getUser(),
    ])
      .then(function () {
        appStore.loading = false
        locationsStore.setCurrentLocationAndFloorplan(locationsStore.locations[0])
      })
  }
  next()
})

export default router
