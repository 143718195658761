<template>
  <div class="camera-states">
    <v-icon
      :size="`${isBig ? 80 : 48}`"
      :class="{'mb-2': showTime}"
    >
      {{ getDeviceDisplayStatus(status).icon }}
    </v-icon>
    <div
      v-if="showTime"
      :class="`camera-states__time ${isBig && 'camera-states__time--big'}`"
    >
      {{ getDeviceDisplayStatus(status).videoText }}
    </div>
  </div>
</template>

<script>
import { getDeviceDisplayStatus } from '@/service/helpers';

export default {
  props: {
    status: { type: String, required: true },
    isBig: { type: Boolean },
    showTime: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return { getDeviceDisplayStatus }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/public/main.scss";

.camera-states {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  &__time {
    @include body-2;
    color: $primaryWhite;
    text-align: center;
    &--big {
      @include body-1;
      text-align: center;
    }
  }
}
</style>
