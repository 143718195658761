<template>
  <eewc-sidebar
    class="add-cameras-sidebar"
    :title="`${t('Add devices')}`"
    :actions="false"
    :drawer-open="open"
    :permanent="open"
    @closeSidebar="$emit('close')"
    v-resize="onResize"
  >
    <template #tabContent>
      <div
        class="floorplan__sidebar-cameras--container"
      >
        <v-card flat>
          <v-card-text>
            <eewc-text-field
              v-model="search"
              :label="t('Search by cameras name...')"
              left-icon="$icon_search"
              clearable
              @input="appStore.$patch({ addCamSidebarKey: appStore.addCamSidebarKey+1 })"
            />
            <v-divider class="add-cameras-sidebar__divider mt-5" />
            <div
              v-if="filteredCameras.length > 0"
              class="add-cameras-sidebar__scrollbox--container scrollbox--smaller"
            >
              <v-virtual-scroll
                class="virtual-scroll"
                :items="filteredCameras"
                :height="sidebarSize"
                :item-height="92"
                :bench="1"
                :key="appStore.addCamSidebarKey"
              >
                <template v-slot="{ item }">
                  <v-row
                    class="camera-row"
                    draggable
                    @dragstart="startDrag($event, item)"
                    @mousedown.stop
                  >
                    <v-col
                      cols="1"
                      class="d-flex align-center"
                    >
                      <v-icon
                        size="24"
                      >
                        $icon_drag_and_drop
                      </v-icon>
                    </v-col>
                    <v-col cols="5">
                      <div class="preview-tile">
                        <preview-image
                          v-if="open"
                          class="add-cameras-sidebar__snapshot"
                          :camera-id="item.id"
                          :max-height="164"
                          :prevent-loading="item?.status?.connectionStatus !== 'online'"
                        />
                        <camera-states
                          v-if="item?.status?.connectionStatus !== 'online'"
                          :status="item.status.connectionStatus"
                          :is-big="false"
                          :show-time="false"
                        />
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="add-cameras-sidebar__camera-name subtitle-2">
                        {{ item.name }}
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-virtual-scroll>
            </div>
            <div
              v-else
              class="add-cameras-sidebar__empty-cameras--container text-center"
            >
              <div class="empty__title--font">
                {{ t('No results found') }}
              </div>
              <div class="empty__text--font">
                {{ t('Please specify your request') }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </template>
  </eewc-sidebar>
</template>

<script setup lang="ts">
import { t } from '@/plugins/i18n';
import { computed, defineProps, onMounted, ref, watch } from 'vue';
import { useAppStore, useFloorplansStore, useFloorsStore, useLocationsStore } from '@/stores'
import PreviewImage from '../../components/PreviewImage.vue';
import CameraStates from '../../components/CameraStates.vue';
import {
  ApiCameraIncludes,
  ApiCameraWithIncludes,
  FloorResponse
} from '@eencloud/eewc-components/src/service/api-types';
import { getCamerasPositionsInFloor, isCameraInsideFloorplan } from '@/service/FloorplanFormulas'

const sidebarSize = ref(0);
const search = ref('')
const appStore = useAppStore();
const floorsStore = useFloorsStore();
const floorPlansStore = useFloorplansStore();
const locationsStore = useLocationsStore();
const availableCameras = ref<ApiCameraWithIncludes[]>([]);


const props = defineProps<{
  open: boolean
}>()

onMounted(() =>  {
  onResize();
})

watch(
  () => [props.open, floorPlansStore.loadingFloorplans, locationsStore.currentLocationCameras],
  () => {
    if (floorsStore.currentFloor && !floorPlansStore.loadingFloorplans) {
      availableCameras.value = getAvailableCameras(floorsStore.currentFloor)
    }
  }
)
const getAvailableCameras = (currentFloor: FloorResponse) => {
  const currentLocationCameras = locationsStore.currentLocationCameras;
  if (!currentLocationCameras?.length || !currentFloor) {
    return []
  }

  const camerasWithPositionsRelatedToCurrentFloor = getCamerasPositionsInFloor(
    currentFloor,
    currentLocationCameras,
    floorPlansStore.currentFloorImage
  )

  return camerasWithPositionsRelatedToCurrentFloor.filter((camera) => {
    const cameraFloor = camera.devicePosition?.floor;
    const isTheSameFloorLevel = cameraFloor === currentFloor?.floorLevel;

    return !Number.isInteger(cameraFloor) || (!isCameraInsideFloorplan(camera) && isTheSameFloorLevel);
  })
}

const filteredCameras = computed(() => {
  if (!search.value) {
    return availableCameras.value
  } else {
    return availableCameras.value.filter((item) => {
      return search.value
          .toLowerCase()
          .split(' ')
          .every((v) => item.name.toLowerCase().includes(v))
    })
  }
})

function startDrag (evt: any, camera: ApiCameraIncludes) {
  const camera_string = JSON.stringify(camera)
  evt.dataTransfer.setData('camera', camera_string)
}

function onResize () {
  sidebarSize.value = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 172;
}
</script>

<style lang="scss" scoped>
.add-cameras-sidebar.sidebar__drawer {
  top: 0 !important;
  min-height: 100%;
}
</style>

<style lang="scss">
@import "../../styles/public/main.scss";
.sidebar-scroller {
  overflow: hidden !important;
}

.virtual-scroll {
  &::-webkit-scrollbar {
    border: unset !important;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border: unset;
    background: $secondaryMedium;
    border-radius: 8px;
    right: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $secondaryMedium;
  }
}

.v-navigation-drawer__content {
  .add-cameras-sidebar__scrollbox--container {
    padding-top: 24px;
    margin-left: -16px;
    width: calc(100% + 40px);
  }

  .floorplan__sidebar-cameras--container {
    overflow: hidden;

    .add-cameras-sidebar__divider {
      margin-left: -20px;
      max-width: unset;
      width: calc(100% + 48px);
    }

    .add-cameras-sidebar__camera-name {
      color: $primary;
      margin-top: 8px;
      text-align: left;
    }

    .camera-row {
      margin-top: 0;
      min-height: 88px;
      padding: 4px 20px 4px 8px;
      margin-left: 8px;
      width: calc(100% - 26px);
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      margin-bottom: 0;

      &:hover {
        background: $backgrounds;
        box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
      }

      &:active {
        opacity: 0.5;
        box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      .col {
        padding: 0;
      }
    }

    .add-cameras-sidebar__snapshot {
      width: 130px;
      height: 80px;
      border-radius: 4px;
      margin-left: 6px;
      overflow: hidden;
      position: relative;
    }

    .preview-tile {
      position: relative;
    }
  }

  .add-cameras-sidebar__empty-cameras--container {
    margin-top: 80px;

    .empty__title--font {
      @include headline-5;
      color: $primary;
      text-align: center;
    }

    .empty__text--font {
      @include body-1;
      text-align: center;
    }
  }
}

</style>
