import api from "@eencloud/eewc-components/src/service/api";
import { defineStore } from "pinia";
import { ref } from 'vue'
import {ApiUserWithIncludes} from "@eencloud/eewc-components/src/service/api-types";

export const useUsersStore = defineStore(
  'users',
  function() {
    const user = ref<ApiUserWithIncludes>();
    const loadingUsers = ref(false);
    const isUserAdmin = ref(false);
    async function getUser() {
      const include = ['permissions'].toString();
      loadingUsers.value = true;
      try {
        const data = await api.getUsers({ include }, 'self');
        if(data) {
          user.value = data as ApiUserWithIncludes;
          isUserAdmin.value = user.value.permissions?.administrator || false;
        }
      } catch (error) {
        console.error(error)
      }
      loadingUsers.value = false;
    }

    return {
      loadingUsers,
      user,
      getUser,
      isUserAdmin
    }
  })
