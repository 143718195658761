<template>
  <div class="d-flex flex-column align-center pb-3">
    <div class="upload-component">
      <h2 class="page-title-1 mt-10 mb-5">
        <template v-if="loadingStatus === 'in-progress'">
          {{ t('Upload in progress') }}
        </template>
        <template v-else-if="loadingStatus === 'done'">
          {{ t('Upload completed') }}
        </template>
        <template v-else>
          {{ t('Upload file') }}
        </template>
      </h2>
      <eewc-upload-file
        :upload-text="uploadText"
        :upload-text-line2="uploadTextLine2"
        :upload-button-label="uploadButtonLabel"
        :accept-type="acceptType"
        :file-size-units="fileSizeUnits"
        :wrong-type-error-message="wrongTypeErrorMessage"
        :upload-file-error-message="uploadFileErrorMessage"
        :uploaded-file-url="newFloorStore.uploadedFileUrl"
        :uploaded-file="newFloorStore.uploadedFile"
        @upload-init="()=>{ loadingStatus = 'in-progress' }"
        @upload-done="(formData)=> onDone(formData)"
        @removed-file="onRemovedFile"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {defineProps, ref} from 'vue'
import { t } from '@/plugins/i18n';
import { useNewFloorStore } from '@/stores'

const props = defineProps<{
  stepIndex: number
}>()

const loadingStatus = ref<'' | 'in-progress' | 'done'>('')
const newFloorStore = useNewFloorStore()

const uploadText = t('Drag and drop png or svg file')
const uploadTextLine2 = t('or')
const uploadButtonLabel = t('Browse files')
const acceptType = t('image/svg+xml, image/png')
const fileSizeUnits = ['Bytes', 'KB', 'MB']
const wrongTypeErrorMessage = t('Upload failed. Supported file types are png and svg.')
const uploadFileErrorMessage = t('Upload failed. Please try again.')

const onDone = (data: {
  formData: FormData,
  fileUrl: string,
  addedFile: File
}) => {
  loadingStatus.value = 'done'
  newFloorStore.$patch({uploadedFileFormData: data.formData, uploadedFileUrl: data.fileUrl, uploadedFile: data.addedFile})
  newFloorStore.setIsCompletedStep(props.stepIndex, true)
}

const onRemovedFile = function () {
  newFloorStore.setIsCompletedStep(props.stepIndex, false)
  newFloorStore.setNextCompletedStep(props.stepIndex, false)
  newFloorStore.$patch({
    uploadedFileFormData: undefined,
    uploadedFileUrl: '',
    uploadedFile: undefined,
  })
}

</script>

<style scoped lang="scss">
@import "../../../../styles/public/main.scss";

.upload-component {
  max-width: 430px;
  width: 100%;
}
.page-title-1{
  @include subtitle-1;
}
</style>
