import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiCameraUpdate,
  ApiCameraWithIncludes,
  ApiDevicePositionCamera
} from '@eencloud/eewc-components/src/service/api-types'
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { t } from '@/plugins/i18n';
import { useMessagingStore } from '@/stores/messaging'
import { MessageManager } from '@/service/MessageManager'
import { MessageManagerI } from '@/service/MessageManagerTypes'
import { useLocationsStore } from '@/stores/locations'
import { useFloorsStore } from '@/stores/floors'

export const useCamerasStore = defineStore(
  'cameras',
  function() {
    const cameras = ref<ApiCameraWithIncludes[]>([]);
    const tags = ref<string[]>([]);
    const loadingCameras = ref(false);
    const locationsStore = useLocationsStore();
    const floorsStore = useFloorsStore();

    window.massageManager = new MessageManager() as MessageManagerI;
    window.massageManager.postMessage(window.parent, 'fetchPreloadedCameras')

    watch(
      () => cameras.value,
      () => {
        if (cameras.value) {
          window.massageManager.postMessage(window.parent, 'updatePreloadedCameras', cameras.value)
        }
      },
      { deep: true }
    )

    const camera = (id: string) => cameras.value.find(cam => cam.id === id)

    function setCameras(camerasToSet: ApiCameraWithIncludes[]) {
      const tagsToSet = new Set<string>();
      camerasToSet = camerasToSet.filter(c => !cameras.value.find(cam => cam.id === c.id));
      camerasToSet.forEach(cam => {
        if(cam.tags)
        cam.tags.forEach(tag => tagsToSet.add(tag));
      });
      tags.value = Array.from(tagsToSet);
      cameras.value = cameras.value.concat(camerasToSet)
    }

    async function getAllCameras() {
      try {
        loadingCameras.value = true;
        cameras.value = [] // this method is set to get all cameras so we clean the array to prevent duplicates
        let totalSize: number | undefined;
        let pageToken: string | undefined;

        while(totalSize === undefined || (totalSize > cameras.value.length && pageToken)) {
          const data = await api.getCameras({ pageToken: pageToken as string, pageSize: 1000 });
          if (data) {
            totalSize = data.totalSize;
            pageToken = data.nextPageToken;
            setCameras(data.results as ApiCameraWithIncludes[]);
            loadingCameras.value = false; // allowing showing the first batch of cameras
          } else break;
        }
      } catch (error) {
        console.error(error)
      }
    }
    function createFailedCamerasNotification(failedCameras: string[]) {
      const messagingStore = useMessagingStore();
      if (failedCameras.length > 0) {
        let camNames = failedCameras.join(', ');
        if (camNames.length > 100) { camNames = camNames.slice(0, 97) + '...' }
        messagingStore.addNotification(
          {
            iconColor: 'negative',
            icon: '$icon_attention',
            title: t('Cameras were not deleted from the floor'),
            body: `${t('These cameras were not deleted from the floor')} : ${ camNames }`,
          }
        )
      }
    }

    async function performCamerasBulkUpdate(changedCameras: ApiCameraWithIncludes[] | undefined, data: ApiDevicePositionCamera) {
      const failedCameras: string[] = [];
      if (changedCameras && changedCameras.length > 0) {
        await Promise.all(changedCameras.map(async (changedCamera) => {
            try {
              await editCamera({ cameraId: changedCamera.id, devicePosition: data })
            } catch {
              failedCameras.push(changedCamera.name);
            }
        }))
        if (failedCameras.length > 0) {
          createFailedCamerasNotification(failedCameras);
        }
      }
    }

    async function editCamera (arg: { cameraId: string, cameraData?: ApiCameraUpdate, devicePosition?: ApiDevicePositionCamera }) {
      const cameraCopyIndex = cameras.value.findIndex((camera) => camera.id === arg.cameraId)
      if (cameraCopyIndex < 0) {
        return
      }

      const camera = cameras.value[cameraCopyIndex]
      let cameraCopy: ApiCameraWithIncludes = JSON.parse(JSON.stringify(camera))
      const cameraRollbackCopy: ApiCameraWithIncludes = JSON.parse(JSON.stringify(camera))

      if (!cameraCopy.devicePosition) {
        cameraCopy.devicePosition = {}
      }
      cameraCopy = { ...cameraCopy, ...arg.cameraData }
      cameraCopy.devicePosition = { ...cameraCopy.devicePosition, ...arg.devicePosition }

      cameras.value.splice(cameraCopyIndex, 1, cameraCopy)

      locationsStore.setCurrentLocationCameras()
      floorsStore.setCurrentFloorCameras()

      try {
        if (arg.cameraData) {
          await api.editCamera(arg.cameraData, arg.cameraId)
        }
      } catch (error) {
        cameras.value.splice(cameraCopyIndex, 1, cameraRollbackCopy)
        locationsStore.setCurrentLocationCameras()
        floorsStore.setCurrentFloorCameras()
        throw error
      }

      try {
        if (arg.devicePosition) {
          delete arg.devicePosition.rangeInFeet;
          await api.editCameraDevicePosition(arg.devicePosition, arg.cameraId)
        }

      } catch (error) {
        cameras.value.splice(cameraCopyIndex, 1, {
          ...cameraCopy,
          devicePosition: cameraRollbackCopy.devicePosition
        })
        locationsStore.setCurrentLocationCameras()
        floorsStore.setCurrentFloorCameras()
        throw error
      }
    }

    return {
      cameras,
      tags,
      loadingCameras,
      camera,
      getAllCameras,
      setCameras,
      performCamerasBulkUpdate,
      editCamera
    }
  }
)
