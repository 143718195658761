import Vue from 'vue'
import VueCookie from 'vue-cookies'
import vuetify from './plugins/vuetify'
import router from './service/router'
import setupInterceptor from '@/service/interceptor'

import App from './App.vue'

import locale from '@/plugins/i18n';
import eewcComponents from '@eencloud/eewc-components/src'
import BitStreamParser from "worker-loader!./service/workers/jpegParser.js";
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueRouter from 'vue-router'
import ApiList from '@eencloud/eewc-components/src/service/api'

import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import { loadGoogle, createHiddenMap } from '@/service/googleMaps/googleMaps'

Vue.use(PiniaVuePlugin)
Vue.use(VueCookie)
Vue.use(VueRouter)
Vue.use(eewcComponents)


export async function requestBaseUrl() {
  /* eslint-disable */
  await ApiList.setBaseUrl(config.apiServer + '/api/v3.0')
}


(async function (){
  try{
    await loadGoogle();
    await createHiddenMap();
  }catch (err) {
    //empty
  }

  setupInterceptor();
  
  const pinia = createPinia()
  const i18n = locale.i18n;
  
  new Vue({
    router,
    vuetify,
    i18n,
    pinia,
    render: h => h(App)
  }).$mount('#app')

  Vue.config.productionTip = false

  window.jpegStreamParser = new BitStreamParser()
  
})()


