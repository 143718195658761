<template>
  <button
    v-if="(latitude > -1) && (longitude > -1)"
    ref="cameraMarkerRef"
    :class="[
      'camera-marker',
      `camera-marker-${camera.id}`,
      {
        'camera-marker--edit': isCameraEditEnabled && floorsStore.isEditCamerasFloorEnabled,
        'camera-marker--active': hoverable && isVisiblePopup,
        'camera-marker--hovered': hoverable && isCameraHovered,
        'camera-marker--online': cameraStatus === 'online',
        'camera-marker--offline': cameraStatus === 'offline',
      }]"
    :style="{ left: longitude + '%', top: latitude + '%'}"
  >
    <!--  CAMERA MARKER CENTER  -->

    <div
      class="camera-marker__center show-during-dragging"
      :draggable="floorsStore.isEditCamerasFloorEnabled"
      @dragstart="onStartDrag($event, camera)"
      @mouseover="onHoverCameraElements(camera.id)"
      @mouseleave="onLeaveCameraElements"
      @mousedown.stop
      @click="onClick"
    />
    
    <!-- FOV -->

    <div
      class="camera-marker__fov show-during-dragging"
      :draggable="floorsStore.isEditCamerasFloorEnabled"
      @dragstart="onStartDrag($event, camera)"
      @mousedown.stop
      @click="onClick"
    >
      <svg
        v-show="appStore.showCameraFOV"
        :height="currentCameraRange * 2"
        :width="currentCameraRange * 2"
      >
        <g
          :transform="`
              translate(${currentCameraRange}, ${currentCameraRange})
              rotate(${isEditingRangeAzimuth ? currentAzimuth : azimuth})
            `"
          @mouseover="onHoverCameraElements(camera.id)"
          @mouseleave="onLeaveCameraElements"
        >
          <circle
            v-if="fov === 360"
            :r="currentCameraRange"
            :cx="0"
            :cy="0"
          />
          <path
            v-else
            :d="`
                M0,0
                L${arcStart.x},${arcStart.y}
                A${currentCameraRange},${currentCameraRange},0,${largeArcFlag},1,${arcEnd.x},${arcEnd.y}
                Z
              `"
          />
        </g>
      </svg>
    </div>

    <!-- EDIT FOV -->
    
    <div
      v-show="appStore.showCameraFOV && floorsStore.isEditCamerasFloorEnabled && isCameraEditEnabled"
      class="camera-marker__edit-fov"
      :style="`transform: rotate(calc(180deg + ${isEditingRangeAzimuth ? currentAzimuth : azimuth}deg))`"
    >
      <div
        ref="cameraMarkerEditCenter"
        class="camera-marker__edit-fov-center"
        :draggable="floorsStore.isEditCamerasFloorEnabled"
        @dragstart="onStartDrag($event, camera)"
        @mouseover="onHoverCameraElements(camera.id)"
        @mouseleave="onLeaveCameraElements"
        @mousedown.stop
        @click="onClick"
      />
      <div
        class="camera-marker__edit-fov-line"
        :style="`height: ${(currentCameraRange) - 2}px;`"
      />
      <div
        class="camera-marker__edit-fov-end"
        @mousedown.stop.prevent="startEditAzimuthRange"
      />
    </div>
    
    <!-- LABEL -->
    
    <eewc-camera-label
      v-show="appStore.showCameraNames"
      :label="label"
      :is-active="isCameraEditEnabled && floorsStore.isEditCamerasFloorEnabled"
      :class="`camera-marker__label camera-marker__label--${labelPosition}`"
      :draggable="floorsStore.isEditCamerasFloorEnabled"
      @dragstart="onStartDrag($event, camera)"
      @mouseover="onHoverCameraElements(camera.id)"
      @mouseleave="onLeaveCameraElements"
      @click="onClick"

    />

    <!-- POPUPS -->

    <div
      v-if="isVisiblePopup"
      ref="popupRef"
      :class="[
        `camera-marker__popup`,
        `camera-marker__popup--${camera.id}`,
        {
          'camera-marker__popup--on-bottom': isPopupBellow,
        }
      ]"
      @mouseover="onHoverCameraElements(camera.id)"
      @mouseleave="onLeaveCameraElements"
      @click="showLiveView(camera?.id)"
    >
      <div
        class="camera-marker__popup__preview"
      >
        <preview-image
          :camera-id="camera.id"
          :prevent-loading="camera?.status?.connectionStatus !== 'online'"
        />
        <camera-states
          v-if="camera?.status?.connectionStatus !== 'online'"
          :status="camera.status.connectionStatus"
          :is-big="false"
          :show-time="false"
          @click="showLiveView(camera?.id)"

        />
        <div class="camera-marker__popup__overlay">
          <div class="overlay-data">
            <div class="overlay-data__date">
              {{getCurrentDate()}}
            </div>
            <div class="overlay-data__actions">
              <eewc-dropdown-menu
                :dark="true"
                :menu-items="menuOptions"
                @select="value => dropDownMenuAction(value, camera.id)"
              />
              <v-icon
                color="white"
                size="24"
                @click="onClosePreview"
              >
                $icon_close
              </v-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="camera-marker__popup__info">
        <div class="camera-marker__popup__info--name">
          <eewc-tooltipped-text
            :text="camera.name"
            :max-width="242"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isVisiblePopup"
      class="camera-marker__popup__triangle"
      @mouseover="onHoverCameraElements(camera.id)"
      @mouseleave="onLeaveCameraElements"
    />
    <eewc-button-group
      v-show="floorsStore.currentEditingCamera && floorsStore.currentEditingCamera.id === camera.id && appStore.showCameraFOV"
      :value="camera.devicePosition?.fieldOfView || 45"
      :items="FOVOptions"
      class="camera-marker__FOV-select"
      @input="changeCameraFOV(camera, $event)"
    />
  </button>
</template>
<script setup lang="ts">
import {ApiCameraWithIncludes, FloorCoords} from "@eencloud/eewc-components/src/service/api-types";
import { computed, ref, ComputedRef, reactive, defineEmits, withDefaults, defineProps, watch, nextTick } from 'vue'
import CameraStates from '@/components/CameraStates.vue'
import PreviewImage from '@/components/PreviewImage.vue'
import { useAppStore, useCamerasStore, useFloorsStore } from '@/stores'
import { t } from '@/plugins/i18n';
import { getCurrentDate, openHistoryBrowser } from '@/service/helpers'
import router from '@/service/router'

const emit = defineEmits<{
  (e: "click"): void,
  (e: "mouseover"): void,
  (e: "dragstart", event: DragEvent): void,
  (e: "mouseleave"): void,
  (e: "changeRangeAzimuth", changes: { azimuth: number, rangeInMeters: number }): void
}>();

const props = withDefaults(defineProps<{
    fov: number,
    azimuth: number,
    cameraStatus: 'online' | 'offline',
    labelPosition: 'left' | 'right' | 'top' | 'bottom',
    label: string,
    latitude: number,
    longitude: number,
    isCameraEditEnabled: boolean,
    camera: ApiCameraWithIncludes,
    rangeInMeters: number,
    imageElementHeight: number,
    imageElementContainer: HTMLElement,
    isDragging: boolean,
    hoverable: boolean
  }>(),
  {
    hoverable: true,
  })

const isCameraHovered = ref(false);



// CALLBACKS
function onHoverCameraElements(cameraId: string) {
  if (props.isCameraEditEnabled || props.isDragging) {
    return;
  }
  isCameraHovered.value = true;
  emit('mouseover');
}

function onLeaveCameraElements() {
  isCameraHovered.value = false;
}

function onStartDrag(evt: DragEvent, camera: ApiCameraWithIncludes) {
  if (evt.dataTransfer) {
    const img = new Image();
    const cameraString = JSON.stringify(camera);

    evt.dataTransfer.setData("camera", cameraString);
    evt.dataTransfer.setDragImage(img, 0, 0);
  }

  emit('dragstart', evt);
}

const onClick = async () => {
  emit('click');
  await nextTick();

  if(floorsStore.isEditCamerasFloorEnabled){
    isVisiblePopup.value = false
    return;
  }

  isVisiblePopup.value = !isVisiblePopup.value;
  if (isVisiblePopup.value) {
    await setPositionOfPopup()
  }
};

const onClosePreview = async () => {
  isCameraHovered.value = false;
  await onClick()
};

// FOV

const referenceAngle = -90;

const floorYInMeters = computed(()=>{
  const lat1 = floorsStore?.currentFloor?.topLeftCoordinates.latitude || 0;
  const lat2 = floorsStore?.currentFloor?.bottomRightCoordinates.latitude || 0;
  const oneLatitudeInMeters = 111.32 * 1000;
  const distance = Math.abs(lat2 - lat1);
  return distance * oneLatitudeInMeters;
});

const currentCameraRange: ComputedRef<number> = computed(() => {
  let rangeInMeters;

  if (isEditingRangeAzimuth.value) {
    rangeInMeters = currentRangeInMeters.value;
  } else {
    rangeInMeters = props.rangeInMeters ? props.rangeInMeters : 5
  }

  const distanceInPixels = rangeInMeters / floorYInMeters.value * props.imageElementHeight;
  return distanceInPixels < 15 ? 15 : distanceInPixels;
})
const polarToCartesian = (distance: number, angleInDegrees: number) => {
  const angleInRadians = ((referenceAngle + angleInDegrees) * Math.PI) / 180.0;
  return {
    x: distance * Math.cos(angleInRadians),
    y: distance * Math.sin(angleInRadians)
  };
};
const arcStart = computed(() => {
  return polarToCartesian(currentCameraRange.value, -props.fov / 2);
})

const arcEnd = computed(() => {
  return polarToCartesian(currentCameraRange.value, props.fov / 2);
})

const largeArcFlag = computed(() => {
  return props.fov <= 180 ? "0" : "1";
})


// EDIT FOV

function useEditCameraAzimuthRange() {
  const cameraMarkerEditCenter = ref();
  const isEditingRangeAzimuth = ref(false);
  const currentAzimuth = ref(0);
  const currentRangeInMeters = ref(0);

  function getCenter(element: HTMLElement) {
    const {left, top, width, height} = element.getBoundingClientRect();
    return {x: left + width / 2, y: top + height / 2}
  }

  function startEditAzimuthRange() {
    currentAzimuth.value = props.azimuth;
    currentRangeInMeters.value = props.rangeInMeters;
    isEditingRangeAzimuth.value = true;
    document.addEventListener('mousemove', changeAzimuthRange);
    document.addEventListener('mouseup', endEditAzimuthRange);
  }

  function changeAzimuthRange(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const {clientX, clientY} = e;
    const arrowCenter = getCenter(cameraMarkerEditCenter.value);
    const angleRad = Math.atan2(clientY - arrowCenter.y, clientX - arrowCenter.x) + Math.PI / 2;
    let rangeInPixels = Math.sqrt(
      Math.pow(clientX - arrowCenter.x, 2) +
      Math.pow(clientY - arrowCenter.y, 2)
    )

    if (rangeInPixels < 15) {
      rangeInPixels = 15;
    }

    currentAzimuth.value = (((angleRad / Math.PI) * 180) + 360) % 360
    currentRangeInMeters.value = (rangeInPixels / props.imageElementHeight) * floorYInMeters.value;
  }

  function endEditAzimuthRange(e) {
    e.preventDefault();
    e.stopPropagation();
    document.removeEventListener('mousemove', changeAzimuthRange);
    document.removeEventListener('mouseup', endEditAzimuthRange);
    emit('changeRangeAzimuth', {azimuth: currentAzimuth.value, rangeInMeters: currentRangeInMeters.value})
    isEditingRangeAzimuth.value = false;
  }
  return {startEditAzimuthRange, isEditingRangeAzimuth, currentRangeInMeters, currentAzimuth, cameraMarkerEditCenter}
}

const {
  startEditAzimuthRange,
  isEditingRangeAzimuth,
  currentRangeInMeters,
  currentAzimuth,
  cameraMarkerEditCenter
} = useEditCameraAzimuthRange();


// POPUPS

const cameraMarkerRef = ref();
const isVisiblePopup = ref(false);
const popupRef = ref();
const appStore = useAppStore();
const floorsStore = useFloorsStore();
const camerasStore = useCamerasStore();
const FOVOptions = [
  { value: 45, icon: "$icon_FOV_45" },
  { value: 360, icon: "$icon_FOV_360" },
];
const isPopupBellow = ref(false);



watch(() => floorsStore.isEditCamerasFloorEnabled, () => {
  if(floorsStore.isEditCamerasFloorEnabled){
    isVisiblePopup.value = false
  }
})

async function setPositionOfPopup() {
  await nextTick();
  const popupHalfWidth = 130;
  const popupHeight = 250;
  let leftMargin = 0;

  const popupElement = popupRef.value;
  const hoveredMarker = cameraMarkerRef.value;
  const markerPosition = hoveredMarker && hoveredMarker.getBoundingClientRect();
  const imageElementContainerPosition = props.imageElementContainer && props.imageElementContainer.getBoundingClientRect();

  if (!markerPosition || !imageElementContainerPosition || !popupElement) {
    return;
  }

  const relativeMarkerLeftPosition = markerPosition.left - imageElementContainerPosition.left;
  const relativeMarkerRightPosition = imageElementContainerPosition.right - markerPosition.right;
  const relativeMarkerTopPosition = markerPosition.top - imageElementContainerPosition.top;
  const noSpaceOnRight = relativeMarkerRightPosition < popupHalfWidth;
  const noSpaceOnLeft = relativeMarkerLeftPosition < popupHalfWidth;

  if (noSpaceOnRight) {
    leftMargin = relativeMarkerRightPosition - popupHalfWidth;
  }

  if (noSpaceOnLeft) {
    leftMargin = popupHalfWidth - relativeMarkerLeftPosition;
  }

  isPopupBellow.value = relativeMarkerTopPosition < popupHeight;
  popupElement.style.left = `${leftMargin}px`;
}

async function changeCameraFOV(camera: ApiCameraWithIncludes, fieldOfView: number ) {
  appStore.loading = true;

  try{
    await camerasStore.editCamera({
      cameraId: camera.id,
      devicePosition: { fieldOfView: fieldOfView }
    })
  } catch (error) { /* empty */ }

  appStore.loading = false;
}

const menuOptions = [
  {
    name: t('History'),
    disabled: false,
    value: 'open-history-browser'
  },
]

function dropDownMenuAction(action: string, cameraId: string) {
  switch (action) {
    case "open-history-browser": {
      openHistoryBrowser(appStore.isNewWebapp, cameraId)
    }
  }
}

const currentRoute = computed(() => router.currentRoute.path);

function showLiveView(cameraId?: string) {
  cameraId && router.push(`${currentRoute.value}/video/${cameraId}`)
}
</script>
<style lang="scss" scoped>
@import "../styles/public/main.scss";

.camera-marker {
  width: 12px;
  height: 12px;
  position: absolute;
  cursor: default;
  transform: translate(-50%, -50%);

  // CAMERA MARKER CENTER
  &__center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    z-index: 4;
    cursor: pointer;
  }
  
  // FOV
  &__fov {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    svg {
      position: absolute;
      pointer-events: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    circle, path {
      fill: $accent;
      opacity: 0.5;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  // EDIT FOV
  &__edit-fov {
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4
  }

  &__edit-fov-center {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px solid $primary;
    background-color: $primaryWhite;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  &__edit-fov-line {
    width: 2px;
    height: 10px;
    background-color: $primary;
    transform: translate(-50%, 0);
    margin: -2px 0;
  }

  &__edit-fov-end {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px solid $primary;
    background-color: $primaryWhite;
    transform: translate(-50%, -50%);
    z-index: 4;
    transition: 0.3s;

    &:hover {
      transform: translate(-50%, -50%) scale(3);
    }
  }
  
  // POPUPS

  &__popup {
    $triangleHeight: 7px;
    $markerDotHeight: 12px;
    position: absolute;
    overflow: visible;
    top: -$triangleHeight;
    left: 0;
    z-index: 5;
    max-height: 250px;
    max-width: 250px;
    background: white;
    border-radius: 4px;
    padding: 4px;
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    transform: translateX(-50%) translateY(-100%);
    cursor: pointer;
    &__preview {
      position: relative;
      width: 242px;
      max-height: 214px;
      height: 100%;
      display: flex;
    }
    &__info {
      padding: 4px 8px;
      width: 100%;
      &--name {
        @include subtitle-2;
      }
    }
    &__triangle {
      width: 0;
      height: 0;
      border-left: $triangleHeight solid transparent;
      border-right: $triangleHeight solid transparent;
      border-top: $triangleHeight solid white;
      position: absolute;
      bottom: 100%;
      left: calc(50% - $triangleHeight);
      z-index: 5;
    }

    &__overlay {
      width: 100%;
      top: 0;
      position: absolute;
      background-color: transparent;
      border-radius: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      height: 72px;
      background: linear-gradient(180deg, #212a34 0%, rgba(33, 42, 52, 0) 100%);
      padding: 8px;
      .overlay-data {
        height: 24px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        color: white !important;

        &__date {
          @include subtitle-2;
        }

        &__actions {
          display: flex;
          align-items: center;
          height: fit-content;
        }
      }
    }

    &--on-bottom {
      transform: translateX(-50%) translateY($markerDotHeight);
      top: $triangleHeight;
      ~ .camera-marker__popup__triangle {
        bottom: -$triangleHeight;
        transform: rotate(180deg);
      }
    }
  }

  &__FOV-select {
    position: absolute;
    top: 60px;
    left:-30px;
    max-width: none;
    z-index: 3;
  }

  // LABEL
  &__label {
    position: absolute;
    opacity: 80%;
    z-index: 3;
    cursor: pointer;

    &--right {
      top: 50%;
      left: 100%;
      padding-left: 10px;
      margin-left: 10px;
      transform: translate(0, -50%);
    }

    &--left {
      top: 50%;
      right: 100%;
      padding-right: 10px;
      margin-right: 10px;
      transform: translate(0, -50%);
    }

    &--top {
      bottom: 100%;
      padding-bottom: 10px;
      margin-bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &--bottom {
      top: 100%;
      padding-top: 10px;
      margin-top: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  // MODIFICATIONS
  &--online {
    .camera-marker {
      &__center {
        border: 2px solid $positiveLight;
        background-color: $positive;
      }
    }
  }

  &--offline {
    .camera-marker {
      &__center {
        border: 2px solid $negativeLight;
        background-color: $negative;
      }
    }
  }

  &-dragging {
    position: absolute;
    z-index: 7;
    pointer-events: none;

    * {
      pointer-events: none !important;
    }

    > * {
      visibility: hidden;
    }

    > .show-during-dragging {
      visibility: visible !important;
    }
  }

  &--active {
    z-index: 7;

    .camera-marker__fov {
      circle, path {
        fill: $accentDark;
        opacity: 90%;
      }
    }

    :deep .label-btn {
      background-color: $accentDark;
    }
  }
  
  &--hovered {
    z-index: 8;

    .camera-marker__fov {
      circle, path {
        fill: $accentDark;
        opacity: 90%;
      }
    }

    :deep .label-btn {
      background-color: $accentDark;
    }
  }

  &:active {
    .camera-marker__fov {
      circle, path {
        fill: $primaryLight;
      }
    }

    :deep .label-btn {
      background-color: $primaryLight;
    }
  }

  &--edit, &--edit:hover {
    z-index: 7;

    .camera-marker__fov {
      circle, path {
        fill: $positive;
      }
    }

    :deep .label-btn {
      background-color: $positive;
    }
  }

  &--edit:hover {
    .camera-marker__fov {
      circle, path {
        opacity: 90%;
      }
    }
  }
}

</style>
