import Vue from 'vue'
import Vuetify from 'vuetify'
// @ts-ignore
import { icons } from '@eencloud/eewc-components/src/assets/icons/icons'
// @ts-ignore
import Theme from '@/service/localization/theme';
import { getThemeName } from '@/utils/utils';

Vue.use(Vuetify);

const theme = new Theme(getThemeName()).colors();

export default new Vuetify({
  icons,
  theme
})
