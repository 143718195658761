import { DeviceStatus, FloorResponse } from '@eencloud/eewc-components/src/service/api-types'
import { t } from '@/plugins/i18n';

export type DeviceStatusDetails = {
  icon: string;
  tooltip: string;
  iconColor?: string;
  videoText: string;
};

export function prepareAvailableFloorsLevels(locationFloors: FloorResponse[], firstLevel?: number, lastLevel?: number, floorName?: string) {

  const prepareFloorsLevelsRange = (start: number, stop: number, step = 1): number[] =>
    Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step);

  const preparedRange = new Set(prepareFloorsLevelsRange(firstLevel || -5, lastLevel || 101));

  locationFloors
    .map(i => i.floorLevel)
    .forEach((usedFloor) => preparedRange.delete(usedFloor));

  return Array
    .from(preparedRange)
    .map(i => ({ text: floorName || `Floor ${i}`, value: i }));
}

export function goFullScreen(ref: Element) { //could go to helper functions
  ref.requestFullscreen();
}

/**
 * function to get the status content for devices. The function is common for all devices.
 * The function will return the icon, tooltip and video text based on the connection status.
 *
 * @param connectionStatus
 * @returns DeviceStatusDetails
 */
export function getDeviceDisplayStatus(connectionStatus: DeviceStatus): DeviceStatusDetails {
  switch (connectionStatus) {
    case 'online':
      return { icon: '$icon_online', tooltip: t('Online'), videoText: t('Online') };
    case 'deviceOffline':
    case 'offline':
    case 'error':
    case 'unknown':
      return { icon: '$icon_offline', tooltip: t('Offline'), videoText: t('Offline') };
    case 'off':
      return { icon: '$icon_off', tooltip: t('Turned off'), videoText: t('Turned off') };
    case 'invalidCredentials':
      return {
        icon: '$icon_attention',
        tooltip: t('Authentication error'),
        iconColor: 'error',
        videoText: t('Authentication error'),
      };
    case 'bridgeOffline':
      return { icon: '$icon_internet_offline', tooltip: t('Internet offline'), videoText: t('Internet offline') };
    default:
      return { icon: '$icon_offline', tooltip: t('Offline'), videoText: t('Offline') };
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function throttle(cb: Function, delay: number) {
  let wait = false;

  return (...args: unknown[]) => {
    if (wait) {
      return;
    }

    cb(...args);
    wait = true;
    setTimeout(() => {
      wait = false;
    }, delay);
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const convertFromMetersToFeet = (meters: number) => {
  return meters * 3.2808
}

export const trimNumber = (number: number, start = 0, end = 8) => {
  return Number(number.toString().slice(start, end))
}

export const getCurrentDate = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const openHistoryBrowser = (isNewWebapp: boolean, cameraId: string) => {
  const width = 730
  const height = 580
  if (isNewWebapp) {
      const url =  `${document.referrer}#/hb/?time=` +
        encodeURIComponent(new Date(new Date()).toUTCString()) +
        '&ids=' +
        cameraId;
      window.massageManager.postMessage(window.parent, 'openHistoryBrowser', url);
  } else {
    const url = `${document.referrer}hist/index.html?id=${cameraId}`;
    window.open(url, cameraId + '_history', 'resizable=1, location=1, menubar=0, width=' + width + ', height=' + height)
  }

}
