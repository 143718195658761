import { render, staticRenderFns } from "./SettingsSidebar.vue?vue&type=template&id=5f7e2a36&scoped=true"
import script from "./SettingsSidebar.vue?vue&type=script&setup=true&lang=ts"
export * from "./SettingsSidebar.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SettingsSidebar.vue?vue&type=style&index=0&id=5f7e2a36&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7e2a36",
  null
  
)

export default component.exports