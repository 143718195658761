import { useLocationsStore, useMessagingStore, useFloorsStore, useCamerasStore, useFloorplansStore, } from '@/stores'
import { t } from '@/plugins/i18n';
import api from "@eencloud/eewc-components/src/service/api";

export const useDeleteDialog = () => {
  const locationsStore = useLocationsStore();
  const messagingStore = useMessagingStore();
  const floorsStore = useFloorsStore();
  const floorplansStore = useFloorplansStore();
  const camerasStore = useCamerasStore();

  const deleteFloorplanWarningText = {
    title: t('Delete the floor plan?'),
    body: t('Are you sure you want to delete this floorplan? This will be removed completely. There is no recovery from this.'),
    buttonMessage: t('Delete floor plan')
  }
  const createDeleteDialog = () => {
    const camerasToUpdate = floorsStore.currentFloorCameras;
    if (floorsStore.currentFloor) {
      const floorplan = floorsStore.currentFloor;
      const floorplanItem = {
        id: floorplan.id,
        name: 'Floor plan'
      }
      messagingStore.deleteDialog({
        type: 'floor plan',
        item: floorplanItem,
        deleteCallback: async () => {
          if (locationsStore.currentLocation?.id && floorplan) {
            try {
              await api.deleteFloor(locationsStore.currentLocation.id, floorplan.id);
              floorsStore.$patch({'floors': floorsStore.floors.filter((floor) => floor.id !== floorplan.id)});
              await floorsStore.setCurrentFloor(floorsStore.floors[0]);
              floorplansStore.deleteCashedFloorplan(locationsStore.currentLocation.id, floorplan.id);
            } catch(error) {
              console.error(error);
            }

            await camerasStore.performCamerasBulkUpdate(camerasToUpdate, { floor: null });
          }
        },
      }, deleteFloorplanWarningText);
    }
  }
  return { createDeleteDialog }
}
