/**
 * Get theme name depending on hostname
 * @return {String} theme name
 */

const getThemeName = () => {
  const NAMES_MAP = {
    eagleeyenetworks: 'EEN',
    wisenetsky: 'WISENETSKY',
    capturecloudvms: 'CAPTURECLOUDVMS',
    mobotixcloud: 'MOBOTIXCLOUD',
  };

  const params = location.href.split('?')[1].split('&')
  const data = {}
  let param;
  
  for (param in params) {
    data[params[param].split('=')[0]] = params[param].split('=')[1]
  }
  const base_url = data['base_url'];
  const HOSTNAME = base_url.split('.')[2];


  const HOST_NAMES = Object.keys(NAMES_MAP);

  for (let i = 0; i < HOST_NAMES.length; i += 1) {
    if (HOSTNAME?.includes(HOST_NAMES[i])) {
      return NAMES_MAP[HOST_NAMES[i]];
    }
  }

  return NAMES_MAP.eagleeyenetworks;
};

export {
  getThemeName,
};
