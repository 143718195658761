/* eslint-disable */
class Theme {
    // eslint-disable-next-line
    application: any;
    // eslint-disable-next-line
    defaultColors: any;
    // eslint-disable-next-line
    themes: any;
    // eslint-disable-next-line
    mapping: any;
  
    constructor(application: string) {
      this.application = application || 'EEN';
      this.mapping = {
        EEN: 'een',
        WISENETSKY: 'wisenetsky',
        CAPTURECLOUDVMS: 'capturecloudvms',
        MOBOTIXCLOUD: 'mobotixcloud',
      };
  
      this.defaultColors = {
        // Fixed colors
        primary: '#212a34',
        primaryMedium: '#313b45',
        primaryLight: '#444d56',
        primaryWhite: '#FFFFFF',
        secondary: '#696f77',
        secondaryMedium: '#969ca4',
        secondaryLight: '#cfd3d9',
        elements: '#e3e9ef',
        backgrounds: '#f7f8fc',
  
        // Action Colors
        positive: '#1fb67f',
        positiveDark: '#009660',
        positiveLight: '#A8E8D0',
        positiveClear: '#e7fff6',
  
        warning: '#E9B600',
        warningDark: '#d3a500',
        warningLight: '#F6E7B3',
        warningClear: '#fff9e4',
  
        negative: '#ee5361',
        negativeDark: '#d0404d',
        negativeLight: '#FFBFC6',
        negativeClear: '#fdeaec',
      };
  
      this.themes = {
        een: {
          colors: {
            accent: '#0091da',
            accentDark: '#0072ac',
            accentLight: '#bbe8ff',
            accentClear: '#f0faff',
          },
        },
        wisenetsky: {
          colors: {
            accent: '#F37321',
            accentDark: '#B85901',
            accentLight: '#FFE2C7',
            accentClear: '#FFF7F0',
          },
        },
        capturecloudvms: {
          colors: {
            accent: '#0C94C8',
            accentDark: '#00739E',
            accentLight: '#B4E9FE',
            accentClear: '#ECFAFF',
          },
        },
        mobotixcloud: {
          colors: {
            accent: '#004594',
            accentDark: '#0E2B55',
            accentLight: '#B4D7FB',
            accentClear: '#E2F0FE',
          },
        },
      };
    }
  
    colors() {
      const mapping = this.mapping[this.application] ? this.mapping[this.application] : 'een';
      const colors = {
        ...this.defaultColors,
        ...(this.themes[mapping] ? this.themes[mapping].colors : this.themes.een.colors),
      };
      return { themes: { light: colors }, options: { customProperties: true } };
    }
  }
  
  export default Theme;